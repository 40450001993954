<template>
  <!-- 首页导航 -->
  <div class="hh_headers" ref="header" :class="headerFixed ? 'issFixed' : ''">
    <div class="hh_logo">
      <img src="@assets/img/logo.png" alt="视联医疗"/>
      <div class="hh_title_wrap">
        <p class="title">远程继续医学教育平台</p>
      </div>
    </div>
    <div class="nav_bar">
      <ul>
        <li
          v-for="(item, index) in navBar"
          :key="index"
          @mouseenter="setCurrentNav(index)"
          @mouseleave="handleLeaveMainHeader"
          ref="MainHeader"
        >
          <router-link
            :to="item.path"
            class="span"
            @click.native="curIndex(index)"
          >
            {{ item.name }}
          </router-link>
        </li>
        <i class="nav-underline" :style="navLineStyle"></i>
      </ul>
    </div>
    <div class="wxq_right_corner">
      <div class="search_input">
        <el-input
          placeholder="请输入感兴趣的内容"
          v-model="inputValue"
          clearable
          @clear="clear"
          @blur="blur"
        >
          <i
            slot="suffix"
            @click="search"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import {EventBus} from "@/assets/js/eventBus";
export default {
  name: "headerBar",
  data() {
    return {
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: 0,
      inputValue: "",
      flag: false,
      navBar: [
        {name: "首页", path: "/home"},
        {name: "直播", path: "/liveList"},
        {name: "点播", path: "/dibbleList"},
        {name: "讲师", path: "/famousTeacher"},
        {name: "资讯", path: "/consultList"},
        {name: "考试", path: "/exam"},
      ],
      currentNav: "",
      currentNavStyle: "",
      activeNavStyle: "",
      isToken: true,
      nav: [
        "/home",
        "/liveList",
        "/dibbleList",
        "/famousTeacher",
        "/consultList",
        "/exam",
      ],
    };
  },
  watch: {},
  computed: {
    navLineStyle() {
      let style = this.currentNavStyle || this.activeNavStyle;
      if (this.flag) {
        return {
          display: "none",
        };
      } else {
        return {
          display: "block",
          left: `${style.left}px`,
        };
      }
    },
  },
  mounted() {
    this.$EventBus.$on("flag", (isShow) => {
      this.flag = isShow;
    });
    this.$EventBus.$on("clearInput", (val) => {
      if (val) {
        this.inputValue = val;
      }
    });
    this.$store.commit("VALUE", "");
    EventBus.$on("curIndex", this.curIndex);
    this.curIndex(0);
    this.$nextTick(() => {
      // 获取吸顶元素的dom
      let header = this.$refs.header;
      // 吸顶元素到top的距离
      this.offsetTop = header.offsetTop;
      // 元素自身的高度
      this.offsetHeight = header.offsetHeight;
      // 监听滚动条
      window.addEventListener("scroll", this.handleScroll);
    });
    let _this = this;
    window.onresize = function () {
      if (_this.nav.includes(_this.$router.path)) {
        _this.setCurrentNav(_this.currentNav);
      }
    };
  },
  // 移除事件监听
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.onresize = null;
  },
  methods: {
    handleScroll() {
      // 得到页面滚动的距离，兼容写法
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断页面滚动的距离是否大于吸顶元素的位置
      this.headerFixed = scrollTop > this.offsetTop - this.offsetHeight;
    },
    search() {
      if (this.inputValue == "") {
        this.$message.error("请输入感兴趣的内容！");
      } else {
        this.$store.commit("VALUE", this.inputValue);
        this.flag = true;
        this.$router.push({path: "/searchList"});
      }
    },
    setCurrentNav(index) {
      // 鼠标移动到导航时，记录临时状态
      this.currentNav = index;
      this.currentNavStyle = this.getNavStyle(index);
    },
    getNavStyle(index) {
      // 根据导航的 index 移动下划线
      let childNodes = this.$refs.MainHeader[index];
      // 根据当前导航的宽度，设置下划线的宽度
      let width = childNodes.clientWidth;
      // let width = childNodes + 10
      // 设置下划线的默认位置
      let left = childNodes.clientWidth / 2 - 17;
      if (index > 0) {
        // 计算左边导航的总宽度，设置为下划线的左移距离
        for (let i = 0; i < index; i++) {
          left += childNodes.clientWidth;
          // left += childNodes.width + 27;
        }
      }
      return {width, left};
    },
    handleLeaveMainHeader() {
      // 鼠标离开导航栏时，重置下划线状态
      this.currentNavStyle = null;
    },
    curIndex(index) {
      this.flag = false;
      this.activeIndex = index;
      this.activeNavStyle = this.getNavStyle(index);
    },
    clear() {
      this.$store.commit("VALUE", "");
      this.inputValue = "";
    },
    blur() {
      this.$store.commit("VALUE", this.inputValue);
    },
  },
};
</script>
<style lang="scss">
.hh_headers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  .hh_logo {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    img {
      margin-left: 20px;
      width: 115px;
      height: 50px;
    }
    .hh_title_wrap {
      margin-left: 20px;
      padding-top: 6px;
      color: #0f5fb6;
      .title {
        font-weight: 600;
        font-size: 20px;
        padding-top: 8px;
      }
    }
  }
  .nav_bar {
    display: flex;
    margin-left: 20px;
    width: 40%;
    line-height: 75px;
    ul {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .nav-underline {
        position: absolute;
        bottom: 0;
        display: block;
        width: 36px;
        height: 3px;
        background-color: #007aff;
        transition: all 0.2s ease-out;
      }
      li {
        width: 95px;
        min-width: 95px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        flex: 1;
        .is_active {
          color: #007aff;
        }
        .link-item-hover {
          padding-bottom: 6px;
          border-bottom: 3px solid;
          color: #007aff;
        }
        .link-item-active {
          padding-bottom: 6px;
          border-bottom: 3px solid;
          // 链接菜单激活样式
          color: #007aff;
        }
        span {
          cursor: pointer;
        }
        a {
          color: #333;
        }
      }
      li:hover {
        a {
          color: #007aff;
        }
        .is_active {
          padding-bottom: 6px;
          border-bottom: 3px solid;
          color: #007aff;
        }
      }
    }
  }
  .search_input {
    position: relative;
    float: right;
    margin-right: 20px;
    width: 210px;
    border: #e7e7e7;
    .el-input__icon {
      cursor: pointer;
    }
  }
  .wxq_right_corner {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
    .el-dropdown {
      .wxq_portrait {
        margin-right: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        vertical-align: middle;
      }
      button {
        padding: 5px 15px;
        border: none;
        border-radius: 12px;
        background: #fff;
        color: #606266;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
      .el-icon--right {
        margin-left: 26px;
      }
    }
  }
}
.issFixed {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}
.hh_logout {
  padding-right: 5px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.hh_login {
  span {
    padding: 0 6px;
    color: #333333;
    cursor: pointer;
  }
}
</style>
