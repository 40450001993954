<template>
  <div class="main" :class="{ fusion_main: from && from != 'unifiedVideo' }">
    <el-container>
      <header-bar v-show="from == null || from == 'unifiedVideo'">
        <div class="hh_login" v-if="isToken">
          <span @click="getLogin">登录</span>
          <!--          |<span @click="getRegister">注册</span>-->
        </div>
        <el-dropdown trigger="click" @command="handleCommand" v-else>
          <button>
            <img
              class="wxq_portrait"
              :src="avatar ? avatar : defaultAvatar"
              alt=""
            />
            {{ titleCut }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal" icon="el-icon-user"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item v-if="isRole" command="manage" icon="el-icon-setting"
              >管理端</el-dropdown-item
            >
            <el-dropdown-item command="exit">
              <img
                class="hh_logout"
                src="@/assets/img/studentImg/home/logout-icon.png"
                alt=""
              />退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </header-bar>
      <el-main :class="{ main_height: from && from != 'unifiedVideo' }">
        <router-view />
      </el-main>
      <el-footer v-show="from == null || from == 'unifiedVideo'">
        <div class="footer_logo">
          <div class="hh_info">
            <img src="@/assets/img/studentImg/home/footLogo-icon.png" alt="" />
            <p class="hh_tit">远程继续医学教育平台</p>
            <p class="hh_bot">
              版权所有 2022 海南视联大健康智慧医疗科技有限公司迁移
            </p>
          </div>
          <div class="hh_public">
            <p class="img_wrap">
              <img
                src="@/assets/img/studentImg/home/QrCodeOfPublicNumber.jpeg"
                alt=""
              />
            </p>
            <p>关注公众号</p>
          </div>
          <div class="hh_public">
            <div class="hh_down">
              <p class="hh_txt">下载</p>
              <div class="hh_ios">
                <img
                  class="hh_icon"
                  src="@/assets/img/studentImg/home/ios-icon.png"
                  alt=""
                /><span class="hh_icon_txt">移动App</span>
                <p class="hh_img_wrap_one downImg_wrap hh_controlIos">
                  <img
                    src="@/assets/img/studentImg/home/iosqrCode.png"
                    alt=""
                  />
                </p>
              </div>
              <div class="hh_and">
                <img
                  class="hh_icon"
                  src="@/assets/img/studentImg/home/andior-icon.png"
                  alt=""
                /><span class="hh_icon_txt">移动App</span>
                <p class="hh_img_wrap_two downImg_wrap hh_controlAnd">
                  <img
                    src="@/assets/img/studentImg/home/andirodqrCode.png"
                    alt=""
                  />
                </p>
              </div>
              <p class="hh_help" @click="downApp">
                <img
                  class="hh_icon"
                  src="@/assets/img/studentImg/home/help-icon.png"
                  alt=""
                /><span class="hh_icon_txt">直播助手</span>
              </p>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
    <login
      v-show="false"
      ref="visLogin"
      @closeDialog="getCloseDialog"
      @registerAccount="getRegisterAccount"
      @retrievePassword="getRetrievePassword"
      @getIsToken="getIsToken"
    />
    <el-dialog
      class="dialog_wrapper"
      v-if="$store.state.isLogin"
      :visible.sync="$store.state.isLogin"
      :modal-append-to-body="false"
      top="20vh"
      width="530px"
    >
      <login
        @closeDialog="getCloseDialog"
        @registerAccount="getRegisterAccount"
        @retrievePassword="getRetrievePassword"
        @getIsToken="getIsToken"
      />
    </el-dialog>
    <el-dialog
      class="dialog_wrapper"
      v-if="isRegisterAccount || $store.state.isRegister"
      :visible.sync="isRegisterAccount || $store.state.isRegister"
      :modal-append-to-body="false"
      @close="getRegisterSuccess"
      top="10vh"
      width="530px"
    >
      <register-account @registerSuccess="getRegisterSuccess" />
    </el-dialog>
    <el-dialog
      class="dialog_wrapper"
      v-if="isRetrievePassword"
      :visible.sync="isRetrievePassword"
      :modal-append-to-body="false"
      @close="getRetrieveSuccess"
      top="20vh"
      width="530px"
    >
      <retrieve-password @retrieveSuccess="getRetrieveSuccess" />
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/assets/js/eventBus";
import headerBar from "@/components/studentsComponent/headerBar";
import login from "@/components/loginRegister/login";
import registerAccount from "@/components/loginRegister/registerAccount";
import retrievePassword from "@/components/loginRegister/retrievePassword";
import { mapGetters } from "vuex";
export default {
  name: "home",
  props: ["from"],
  components: {
    headerBar,
    login, //登录
    registerAccount, //注册账号
    retrievePassword, //忘记密码
  },
  data() {
    return {
      roleCode: '',
      roleLength: '',
      isRole: true,
      downUrl:
        "https://slyl-gzh-1301295327.cos.ap-beijing.myqcloud.com/upload/temp/%E8%A7%86%E8%81%94%E5%8C%BB%E7%96%97%E7%9B%B4%E6%92%AD%E5%8A%A9%E6%89%8B-V1.0.0-%E5%AE%89%E8%A3%85%E5%8C%85.exe",
      isLogin: false, //登录弹框
      isRegisterAccount: false, //注册账号弹框
      isRetrievePassword: false, //忘记密码弹框,
      titleCut: "学员端",
      isToken: true,
      token: "",
      avatar: "",
      defaultAvatar: require("@/assets/img/studentImg/liveDetail/tim-avatar.png"),
    };
  },
  watch: {
    "$store.state.userInfo"(val) {
      if (val) {
        this.avatar = val.avatar;
        this.roleCode = val.roleIds
        console.log("🚀 ~ file: home.vue ~ line 184 ~ this.roleCode", this.roleCode)
        this.roleLength = val.roleIds.length
        console.log("🚀 ~ file: home.vue ~ line 186 ~ this.roleLength", this.roleLength)
        if(this.roleLength = 1 && this.roleCode == 2){
          this.isRole = false
        }else{
          this.isRole = true
        }
      }
    },
  },
  mounted() {
    let username = this.getQueryString("username");
    let password = this.getQueryString("password");
    if (username && password) {
      // 地址栏传入账号密码
      this.mixSupport();
    }
    if (
      sessionStorage.getItem("educationToken") ||
      localStorage.getItem("educationToken")
    ) {
      this.isToken = false;
      this.avatar =
        JSON.parse(sessionStorage.getItem("profile")).avatar ||
        JSON.parse(localStorage.getItem("profile"))?.avatar;
    }
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener('popstate', this.goBack, false);
    // }
    this.roleLength = JSON.parse(sessionStorage.getItem("profile")) ? JSON.parse(sessionStorage.getItem("profile")).roleIds.length : ''
    this.roleCode = JSON.parse(sessionStorage.getItem("profile")) ? JSON.parse(sessionStorage.getItem("profile")).roleIds : ''
    if(this.roleLength = 1 && this.roleCode == 2){
      // this.isToken = true;
      this.isRole = false
    }
  },
  methods: {
    downApp() {
      const a = document.createElement("a");
      const objectUrl = this.downUrl;
      a.download = "视联医疗直播助手";
      a.href = objectUrl;
      a.click();
    },
    goBack() {
      this.$store.commit("PARENTID", null);
      this.$store.commit("PARENTChiLD", null);
      // this.$store.state.subjectId && this.$store.commit('subjectId', null)
      // this.$store.state.chilSubjectId && this.$store.commit('chilSubjectId', null)
      sessionStorage.removeItem("dibbledata");
      sessionStorage.removeItem("livedata");
      sessionStorage.removeItem("secondList");
      this.$EventBus.$emit("clearInput", null);
    },
    getLogin() {
      //登录
      this.$store.state.isLogin = true;
    },
    getRegister() {
      //注册
      this.$store.state.isRegister = true;
    },
    getIsToken(val) {
      //登录成功后显示下拉菜单
      if (val) {
        this.isToken = false;
      }
    },
    handleCommand(val) {
      //头部下拉菜单
      if (val == "manage") {
        this.titleCut = "管理端";
        this.$router.push("/myCourses");
      } else if (val == "personal") {
        this.$EventBus.$emit("flag", true);
        this.$router.push({ name: "personalCenter" });
      } else if (val == "exit") {
        sessionStorage.clear();
        localStorage.removeItem("educationToken");
        localStorage.removeItem("IMSign");
        localStorage.removeItem("profile");
        this.isToken = true;
        this.$router.push("/home");
      }
    },
    getCloseDialog() {
      //关闭登录弹框
      this.isLogin = false;
    },
    getRegisterAccount() {
      //注册账号
      this.isLogin = false;
      this.isRegisterAccount = true;
    },
    getRegisterSuccess() {
      //注册成功
      this.isRegisterAccount = false;
      this.isLogin = true;
    },
    getRetrievePassword() {
      //找回密码
      this.isLogin = false;
      this.isRetrievePassword = true;
    },
    getRetrieveSuccess() {
      //找回密码成功
      this.isRetrievePassword = false;
      this.isLogin = true;
    },
    mixSupport() {
      // 地址栏传入账号密码 混合支持PS
      let username = this.getQueryString("username");
      let password = this.getQueryString("password");
      this.$refs.visLogin.mixSupport({
        mode: "password",
        password: password,
        username: username,
      });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var curIndex = window.location.hash.indexOf("?") + 1;
      var r = window.location.hash.substr(curIndex).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
    this.$EventBus.$off("sendVerifi");
  },
};
</script>
<style lang="scss" scoped>
.el-main {
	padding: 0;
}
.el-footer {
	padding: 0;
	height: 213px !important;
	background: linear-gradient(213deg, #67a4ff 0%, var(--colorBlue) 100%);
	.footer_logo {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding-top: 53px;
		width: 833px;
		color: #fff;
		.hh_public {
			text-align: center;
			font-size: 14px;
			.hh_txt {
				padding-bottom: 2px;
				width: 88px;
				border-bottom: 1px solid;
				text-align: left;
			}
			.hh_ios {
				position: relative;
				display: flex;
				padding-top: 5px;
				padding-bottom: 2px;
				cursor: pointer;
			}
			.hh_ios:hover .hh_controlIos {
				display: block;
			}
			.hh_and {
				position: relative;
				display: flex;
				padding-bottom: 2px;
				cursor: pointer;
			}
			.hh_and:hover .hh_controlAnd {
				display: block;
			}
			.hh_help {
				display: flex;
				cursor: pointer;
			}
			.hh_icon {
				position: relative;
				padding-right: 8px;
				width: 16px;
				height: 18px;
				vertical-align: bottom;
			}
			.hh_icon_txt {
				padding-top: 4px;
			}
			.hh_img_wrap_one {
				position: absolute;
				top: -12px;
				left: 105px;
				display: none;
				width: 88px;
				height: 88px;
				border-radius: 6px;
				background: #fff;
			}
			.hh_img_wrap_two {
				position: absolute;
				top: -20px;
				left: 105px;
				display: none;
				width: 88px;
				height: 88px;
				border-radius: 6px;
				background: #fff;
			}
			.downImg_wrap::before {
				position: absolute;
				top: 25px;
				left: -8px;
				width: 0;
				height: 0;
				border-width: 8px 8px 8px 0;
				border-style: solid;
				border-color: transparent #f7f7f7 transparent transparent;
				border-right-color: #fff;
				content: "\A";
			}
			img {
				padding-top: 5px;
				width: 78px;
				height: 78px;
			}
		}
		.hh_info {
			text-align: center;
			.hh_tit {
				padding-top: 9px;
				font-weight: 500;
				font-size: 20px;
			}
			.hh_eng {
				padding-top: 5px;
				font-size: 8px;
			}
			.hh_bot {
				padding-top: 24px;
				font-weight: 500;
				font-size: 12px;
			}
			img {
				width: 117px;
				height: 42px;
			}
		}
	}
}
// .hh_down{
//   display: flex;
// }
</style>
