export const checkPhone = (rule, value, callback) => {
  //手机号校验
  if (!value) {
    return callback(new Error('请输入手机号'));
  } else {
    const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
}
