<template>
  <!--注册账号-->
  <div class="wxq_sign">
    <p class="wxq_sign_title" :class="{'color_blue': !success}">注册账号</p>
    <el-form :model="formData" :rules="rules" ref="registerForm" class="form" v-if="success">
      <el-form-item class="wxq_account" prop="account">
        <span slot="label">账号 <i class="color_blue">（可以是手机号且设置后不可修改）</i></span>
        <el-input v-model.trim="formData.account" clearable placeholder="字母、数字或“_”，6-30位"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model.trim="formData.username" clearable placeholder="请输入您的用户名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model.trim="formData.mobile" clearable placeholder="请输入您的手机号"></el-input>
      </el-form-item>
      <el-form-item class="wxq_auth" label="验证码" prop="code">
        <el-input v-model.trim="formData.code" clearable placeholder="请点击获取验证码并填写">
          <el-button :disabled="isDisabled" :class="{'btn_disabled': isDisabled}" class="btn bg_lightGreen box_shadow"
                     slot="append" @click="getVerifyCode('registerForm')">{{ authCode }}
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model.trim="formData.password" clearable placeholder="请输入您的密码"></el-input>
      </el-form-item>
      <el-form-item class="wxq_again_password" prop="againPassword">
        <el-input v-model.trim="formData.againPassword" clearable placeholder="请再次输入您的密码"></el-input>
      </el-form-item>
      <div class="wxq_voluntarily">
        <el-checkbox v-model="checked">我同意</el-checkbox>
        <a color="color_blue" href="">服务条款</a>、<a color="color_blue" href="">隐私权政策</a>
      </div>
      <el-button class="btn btn_blue wxq_register box_shadow" @keyup.enter.native="accountLogin('accountForm')"
                 @click="getRegister('registerForm')">注册<i class="el-icon-right"></i>
      </el-button>
    </el-form>
    <div class="wxq_sign_success" v-else>
      <img src="@/assets/img/success-icon.png" alt="">
      <p>恭喜您，已注册成功！将<span class="color_blue" @click="getSuccess">返回从新登录</span></p>
      <el-button class="btn btn_blue" @click="getSuccess">确定</el-button>
    </div>
  </div>
</template>

<script>
import API from "@/apis/common";
import CommonUtils from "@/utils";
import {checkPhone} from '@/assets/js/validateRules'
export default {
  name: "registerAccount",
  data() {
    return {
      authCode: '获取验证码',
      seconds: 60,
      isDisabled: false,
      checked: false,
      success: true,
      formData: {
        account: '',//账号
        username: '',//用户名
        mobile: '',//手机号
        code: '',//验证码
        password: '',//密码
        againPassword: '',//再次输入密码
      },
      verifyCodeData: {
        mobile: '',
        type: 4
      },
      rules: {
        account: [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        mobile: [
          {required: true, validator: checkPhone, trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入6-8位密码', trigger: 'blur'},
          {min: 6, max: 8, message: '请输入6-8位密码'}
        ],
        againPassword: [
          {required: true, message: '请输入6-8位密码', trigger: 'blur'},
          {min: 6, max: 8, message: '请输入6-8位密码'}
        ],
      },
    }
  },
  mounted() {
  },
  methods: {
    getRegister(formName) {//注册
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      this.success = false
      //   }
      // });
    },
    getSuccess() {//注册成功
      this.$emit('registerSuccess')
    },
    getVerifyCode(formName) { //获取验证码
      this.verifyCodeData.mobile = this.formData.mobile;
      this.$refs[formName].validateField('mobile', (valid) => {
        if (!valid) {
          API.getVerifyCode(CommonUtils.parseToParams(this.verifyCodeData)).then(res => {
            if (res && !res.status) {
              this.timmer = window.setInterval(() => {
                if (this.seconds == 1) {
                  this.isDisabled = false;
                  window.clearInterval(this.timmer);
                  this.authCode = '重新发送';
                  this.seconds = 60;
                } else {
                  this.isDisabled = true;
                  this.seconds -= 1;
                  this.authCode = this.seconds + ' s';
                }
              }, 1000);
            }
          })
        } else {
          return false
        }
      });
    },
    checkPhone(rule, value, callback) { //手机号校验
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.wxq_sign {
	padding: 30px;
	width: 488px;
	text-align: center;
	.wxq_sign_title {
		margin-bottom: 28px;
		color: #0a1629;
		font-size: 18px;
	}
	.wxq_account {
		::v-deep .el-form-item__label {
			width: auto;
			i {
				font-style: normal;
				font-size: 12px;
			}
		}
	}
	.wxq_auth {
		::v-deep .el-input-group__append {
			padding-left: 8px;
			border: none;
			.btn {
				padding: 0 16px;
				width: 102px;
				color: #fff;
				font-weight: 400;
			}
			.btn_disabled {
				background: #ced5e0;
			}
		}
	}
	.wxq_again_password {
		::v-deep .el-form-item__content {
			margin-top: 5px;
		}
	}
	.wxq_voluntarily {
		margin: 30px 0 55px;
		text-align: left;
		font-size: 16px;
		.el-checkbox {
			color: #7d8592;
			font-weight: normal;
			::v-deep .el-checkbox__label {
				font-size: 16px;
			}
		}
		a {
			text-decoration: underline;
		}
	}
	.wxq_register {
		width: 100%;
		font-weight: normal;
		font-size: 16px;
		line-height: 40px;
		.el-icon-right {
			margin-left: 6px;
			vertical-align: text-bottom;
		}
		.el-icon-right:before {
			font-weight: 600;
			font-size: 20px;
		}
	}
	.wxq_sign_success {
		img {
			margin: 36px 0;
			width: 45px;
		}
		p {
			margin-bottom: 102px;
			color: #7d8592;
			span {
				cursor: pointer;
			}
		}
		button {
			width: 400px;
			font-weight: 400;
			font-size: 16px;
		}
	}
	::v-deep .el-form-item {
		margin-bottom: 12px;
	}
	::v-deep .el-form-item__label {
		color: #7d8592;
		text-align: left;
	}
	::v-deep .el-form-item__content {
		margin-top: 40px;
		padding-left: 0;
	}
	::v-deep .el-form-item__error {
		padding-left: 0;
	}
	::v-deep .el-input__inner {
		border: 1px solid #d8e0f0;
		box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
	}
}
</style>
