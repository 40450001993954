<template>
  <!--登录页面组件，可引用页面，也可弹框-->
  <div class="wxq_right">
    <ul class="wxq_tab">
      <li
        :class="{ 'active color_blue': activeName == 'mobile' }"
        @click="changeTab('mobile')"
      >
        短信登录
      </li>
      <li
        :class="{ 'active color_blue': activeName == 'password' }"
        @click="changeTab('password')"
      >
        密码登录
      </li>
    </ul>
    <div class="wxq_panel" v-show="activeName == 'mobile'">
      <el-form
        :model="formData.mobile"
        :rules="rules.mobile"
        ref="mobileForm"
        class="form"
      >
        <el-form-item label="手机号" prop="username">
          <el-input
            v-model.trim="formData.mobile.username"
            clearable
            placeholder="请输入您的手机号"
            @keyup.enter.native="mobileLogin('mobileForm')"
          ></el-input>
        </el-form-item>
        <el-form-item class="wxq_auth" label="验证码" prop="code">
          <el-input
            v-model.trim="formData.mobile.code"
            clearable
            placeholder="请点击获取验证码并填写"
            @keyup.enter.native="mobileLogin('mobileForm')"
          >
            <el-button
              :disabled="isDisabled"
              :class="{ btn_disabled: isDisabled }"
              class="btn bg_lightGreen box_shadow"
              slot="append"
              @click="getVerifyCode('mobileForm')"
              >{{ authCode }}
            </el-button>
          </el-input>
        </el-form-item>
        <div class="wxq_voluntarily">
          <el-checkbox v-model="automaticLogin">下次自动登录</el-checkbox>
        </div>
        <el-button
          class="btn btn_blue wxq_register"
          @keyup.enter.native="accountLogin('accountForm')"
          @click="mobileLogin('mobileForm')"
          >登录 <i class="el-icon-right"></i>
        </el-button>
        <!--        <p class="wxq_enroll" @click="getRegisterAccount">注册账号</p>-->
      </el-form>
    </div>
    <div class="wxq_panel" v-show="activeName == 'password'">
      <el-form
        :model="formData.password"
        :rules="rules.password"
        ref="passwordForm"
        class="form"
      >
        <el-form-item label="账号" prop="username">
          <el-input
            v-model.trim="formData.password.username"
            clearable
            placeholder="请输入您的账号"
            @keyup.enter.native="passwordLogin('passwordForm')"
          ></el-input>
        </el-form-item>
        <el-form-item class="wxq_auth" label="密码" prop="password">
          <el-input
            show-password
            v-model.trim="formData.password.password"
            clearable
            placeholder="请输入您的密码"
            @keyup.enter.native="passwordLogin('passwordForm')"
          >
          </el-input>
        </el-form-item>
        <div class="wxq_voluntarily">
          <el-checkbox v-model="remember">记住账号</el-checkbox>
          <span @click="getRetrievePassword">忘记密码</span>
        </div>
        <el-button
          class="btn btn_blue wxq_register box_shadow"
          @keyup.enter.native="passwordLogin('accountForm')"
          @click="passwordLogin('passwordForm')"
          >登录 <i class="el-icon-right"></i>
        </el-button>
        <!--        <p class="wxq_enroll color_blue" @click="getRegisterAccount">注册账号</p>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/apis/common";
import personalCenterAPI from "@/apis/studentsEndAPI/personalCenterAPI";
import CommonUtils from "@/utils";
import {checkPhone} from "@/assets/js/validateRules";
export default {
  name: "loginCommon",
  data() {
    return {
      activeName: "mobile", //短信密码登录切换
      authCode: "获取验证码",
      seconds: 60, //验证码倒计时
      isDisabled: false, //验证码按钮禁用
      automaticLogin: false, //自动登录
      remember: false, //记住账号
      verifyCodeData: {
        mobile: "",
        type: 1,
      },
      formData: {
        mobile: {
          mode: "code",
          username: "",
          code: "",
        },
        password: {
          mode: "password",
          username: "",
          password: "",
        },
      },
      rules: {
        mobile: {
          //短信登录验证
          username: [
            { required: true, validator: checkPhone, trigger: "blur" },
          ],
          code: [
            { required: true, message: "请输入验证码", trigger: "blur" },
            { min: 6, max: 8, message: "请输入6位验证码" },
          ],
        },
        password: {
          //密码登录验证
          username: [
            { required: true, message: "请输入账号", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入6-8位密码", trigger: "blur" },
            { min: 6, max: 8, message: "请输入6-8位密码" },
          ],
        },
      },
    };
  },
  mounted() {
    if (localStorage.getItem("rememberAccount")) {
      let rememberAccount = JSON.parse(localStorage.getItem("rememberAccount"));
      this.formData.password.username = rememberAccount.username;
      this.remember = true;
    }
  },
  methods: {
    // home页调用，融合PS
    mixSupport(param) {
      this.doLogin(param);
    },
    mobileLogin(formName) {
      //短信登录
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.login(CommonUtils.parseToParams(_this.formData.mobile)).then(
            (res) => {
              if (res && !res.status) {
                let token = res.type + " " + res.token;
                this.automaticLogin
                  ? localStorage.setItem("educationToken", token)
                  : sessionStorage.setItem("educationToken", token);
                this.$emit("getIsToken", token);
                this.getProfile();
                this.getIMSign();
                this.$store.state.isLogin = false;
                // EventBus.$emit('examInit')
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    passwordLogin(formName) {
      //密码登录
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doLogin();
        } else {
          return false;
        }
      });
    },
    doLogin(param) {
      this.formData.password.password = this.$md5(
        this.formData.password.password
      );
      API.login(
        CommonUtils.parseToParams(param || this.formData.password)
      ).then((res) => {
        if (res && !res.status) {
          this.remember
            ? localStorage.setItem(
                "rememberAccount",
                JSON.stringify(this.formData.password)
              )
            : localStorage.removeItem("rememberAccount");
          let token = res.type + " " + res.token;
          sessionStorage.setItem("educationToken", token);
          this.$emit("getIsToken", token);
          this.getProfile();
          this.getIMSign();
          this.$store.state.isLogin = false;
          // EventBus.$emit('examInit')
        }
      });
    },
    async getProfile() {
      //获取用户信息
      let data = await personalCenterAPI.getProfile();
      if (data) {
        this.$store.commit("USER_INFO", data);
        this.automaticLogin
          ? localStorage.setItem("profile", JSON.stringify(data))
          : sessionStorage.setItem("profile", JSON.stringify(data));
      }
    },
    async getIMSign() {
      //获取腾讯imSign
      let data = await API.getIMSign();
      if (data) {
        this.automaticLogin
          ? localStorage.setItem("IMSign", JSON.stringify(data))
          : sessionStorage.setItem("IMSign", JSON.stringify(data));
        this.$store.commit("IMSign", data);
      }
    },
    getRegisterAccount() {
      //注册账号
      this.$emit("registerAccount");
    },
    getRetrievePassword() {
      //找回密码
      this.$emit("retrievePassword");
    },
    getVerifyCode(formName) {
      //获取验证码
      this.verifyCodeData.mobile = this.formData.mobile.username;
      this.$refs[formName].validateField("username", (valid) => {
        if (!valid) {
          API.getVerifyCode(
            CommonUtils.parseToParams(this.verifyCodeData)
          ).then((res) => {
            if (res && !res.status) {
              this.timmer = window.setInterval(() => {
                if (this.seconds == 1) {
                  this.isDisabled = false;
                  window.clearInterval(this.timmer);
                  this.authCode = "重新发送";
                  this.seconds = 60;
                } else {
                  this.isDisabled = true;
                  this.seconds -= 1;
                  this.authCode = this.seconds + " s";
                }
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    changeTab(tabName) {
      //点击切换登录方式
      this.activeName = tabName;
      this.$refs["mobileForm"].clearValidate();
      this.$refs["passwordForm"].clearValidate();
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_right {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 488px;
  height: 480px;
  .wxq_tab {
    margin-bottom: 16px;
    width: 40%;
    li {
      float: left;
      padding-bottom: 10px;
      width: 50%;
      color: #91929e;
      text-align: center;
      font-size: 18px;
      line-height: 36px;
      cursor: pointer;
    }
    .active {
      position: relative;
    }
    .active::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 32px;
      height: 4px;
      background-color: var(--colorBlue);
      content: "";
      transform: translate(-50%, -50%);
    }
  }
  .wxq_tab::after {
    display: block;
    clear: both;
    content: "";
  }
  .wxq_panel {
    width: 400px;
    text-align: center;
    .wxq_auth {
      ::v-deep .el-input-group__append {
        padding-left: 8px;
        border: none;
        .btn {
          padding: 0 16px;
          width: 102px;
          color: #fff !important;
          font-weight: 400;
        }
        .btn_disabled {
          background: #ced5e0 !important;
        }
      }
    }
    .wxq_voluntarily {
      padding: 10px 0 30px 0;
      text-align: left;
      .el-checkbox {
        color: #7d8592;
        font-weight: normal;
      }
      span {
        margin-left: 260px;
        color: #7d8592;
        cursor: pointer;
      }
    }
    .wxq_register {
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 40px;
      .el-icon-right {
        margin-left: 6px;
        vertical-align: text-bottom;
      }
      .el-icon-right:before {
        font-weight: 600;
        font-size: 20px;
      }
    }
    .wxq_enroll {
      margin-top: 22px;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .wxq_right_footer {
    position: absolute;
    bottom: -50px;
    left: 50%;
    color: #91929e;
    transform: translate(-50%, -50%);
  }
  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
  ::v-deep .el-form-item__label {
    color: #7d8592;
    text-align: left;
  }
  ::v-deep .el-form-item__content {
    margin-top: 40px;
    padding-left: 0;
  }
  ::v-deep .el-form-item__error {
    padding-left: 0;
  }
  ::v-deep .el-input__inner {
    border: 1px solid #d8e0f0;
    box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.22);
  }
}
</style>
