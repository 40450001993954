<template>
  <!--找回密码-->
  <div class="wxq_retrieve">
    <p class="wxq_retrieve_title" :class="{'color_blue': !success}">找回密码</p>
    <el-form :model="formData" :rules="rules" ref="mobileForm" class="form" v-if="success">
      <div v-if="isNextStep">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.trim="formData.phone" clearable placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item class="wxq_auth" label="验证码" prop="verifyCode">
          <el-input v-model.trim="formData.verifyCode" clearable placeholder="请点击获取验证码并填写">
            <el-button :disabled="isDisabled" :class="{'btn_disabled': isDisabled}" class="btn bg_lightGreen box_shadow"
                       slot="append" @click="getVerifyCode('mobileForm')">{{ authCode }}
            </el-button>
          </el-input>
        </el-form-item>
        <el-button class="btn btn_blue wxq_register" @click="getNextStep('mobileForm')">下一步<i class="el-icon-right"></i>
        </el-button>
      </div>
      <div v-else>
        <el-form-item label="密码" prop="password">
          <el-input v-model.trim="formData.password" clearable placeholder="请输入您的密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword">
          <el-input v-model.trim="formData.newPassword" clearable placeholder="请再次输入您的密码"></el-input>
        </el-form-item>
        <el-button class="btn btn_blue wxq_register box_shadow" @click="submit('mobileForm')">确定
        </el-button>
      </div>
    </el-form>
    <div class="wxq_retrieve_success" v-else>
      <img src="@/assets/img/success-icon.png" alt="">
      <p>恭喜您，密码设置成功！将<span class="color_blue" @click="getSuccess">返回从新登录</span></p>
      <el-button class="btn btn_blue" @click="getSuccess">确定</el-button>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils";
import API from "@/apis/common";
import {checkPhone} from '@/assets/js/validateRules'

export default {
  name: "retrievePassword",
  data() {
    return {
      authCode: '获取验证码',
      seconds: 60,
      isDisabled: false,
      checked: false,
      success: true,
      isNextStep: true,
      formData: {
        phone: '',
        verifyCode: '',
        password: '',
        newPassword: '',
      },
      verifyCodeData: {
        mobile: '',
        type: 2
      },
      rules: {
        phone: [
          {required: true, validator: checkPhone, trigger: 'blur'}
        ],
        verifyCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, validator: this.validatePass, trigger: 'blur'},
          {min: 6, max: 8, message: '请输入6-8位密码'}
        ],
        newPassword: [
          {required: true, validator: this.validateAgain, trigger: 'blur'},
          {min: 6, max: 8, message: '请输入6-8位密码'}
        ],
      },
    }
  },
  mounted() {
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.getPassword(this.formData).then(res => {
            if (res && !res.status) {
              this.success = false
            }
          })
        }
      });
    },
    getNextStep(formName) { //下一步
      const validateList = []
      this.$refs[formName].validateField(['phone', 'verifyCode'], (valid) => {
        validateList.push(valid)
      });
      if (validateList.every((item) => item === '')) {
        this.isNextStep = false
      } else {
        return false;
      }
    },
    getVerifyCode(formName) { //获取验证码
      this.verifyCodeData.mobile = this.formData.phone;
      this.$refs[formName].validateField('phone', (valid) => {
        if (!valid) {
          API.getVerifyCode(CommonUtils.parseToParams(this.verifyCodeData)).then(res => {
            if (res && !res.status) {
              this.timmer = window.setInterval(() => {
                if (this.seconds == 1) {
                  this.isDisabled = false;
                  window.clearInterval(this.timmer);
                  this.authCode = '重新发送';
                  this.seconds = 60;
                } else {
                  this.isDisabled = true;
                  this.seconds -= 1;
                  this.authCode = this.seconds + ' s';
                }
              }, 1000);
            }
          })
        } else {
          return false
        }
      });
    },
    getSuccess() {
      this.$emit('retrieveSuccess')
    },
    validatePass(rule, value, callback) {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    },
    validateAgain(rule, value, callback) {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value != this.formData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.wxq_retrieve {
	padding: 30px;
	width: 488px;
	text-align: center;
	.wxq_retrieve_title {
		margin-bottom: 28px;
		color: #0a1629;
		font-size: 18px;
	}
	.wxq_auth {
		::v-deep .el-input-group__append {
			padding-left: 8px;
			border: none;
			.btn {
				padding: 0 16px;
				width: 102px;
				color: #fff;
				font-weight: 400;
			}
			.btn_disabled {
				background: #ced5e0;
			}
		}
	}
	.wxq_register {
		margin-top: 44px;
		width: 100%;
		font-weight: normal;
		font-size: 16px;
		line-height: 40px;
		.el-icon-right {
			margin-left: 6px;
			vertical-align: text-bottom;
		}
		.el-icon-right:before {
			font-weight: 600;
			font-size: 20px;
		}
	}
	.wxq_retrieve_success {
		img {
			margin: 36px 0;
			width: 45px;
		}
		p {
			margin-bottom: 102px;
			color: #7d8592;
			span {
				cursor: pointer;
			}
		}
		button {
			width: 400px;
			font-weight: 400;
			font-size: 16px;
		}
	}
	::v-deep .el-form-item {
		margin-bottom: 12px;
	}
	::v-deep .el-form-item__label {
		color: #7d8592;
		text-align: left;
	}
	::v-deep .el-form-item__content {
		margin-top: 40px;
		padding-left: 0;
	}
	::v-deep .el-form-item__error {
		padding-left: 0;
	}
	::v-deep .el-input__inner {
		border: 1px solid #d8e0f0;
		box-shadow: 0 1px 2px 0 rgba(184, 200, 224, .22);
	}
}
</style>

